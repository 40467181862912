import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';

import { breakpoint } from '../../theme';

import Slice from '../Slice';

const Section = styled.div`
  margin-bottom: ${rem(35)};

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

function SliceZone({ slices }) {
  return (
    slices &&
    slices.map((slice, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Section key={index}>
        <Slice slice={slice} />
      </Section>
    ))
  );
}

SliceZone.propTypes = {
  slices: PropTypes.arrayOf(PropTypes.object),
};

export default SliceZone;
