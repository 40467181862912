/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { DateTime } from 'luxon';

import { RichText } from 'prismic-reactjs';

import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import SliceZone from '../components/SliceZone';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidthText};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(80)};
`;

function Article({ data, pageContext }) {
  useLangRedirect(pageContext);

  const { article: page } = data.prismic;

  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      <Hero
        preHeading="Artikel"
        heading={RichText.asText(page.title)}
        image={page.featured_imageSharp}
        pageTitle
      >
        {DateTime.fromISO(page._meta.firstPublicationDate).toFormat(
          'dd LLL, yyyy',
        )}
      </Hero>

      <Container>
        <Row>
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

Article.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const query = graphql`
  query($uid: String!, $lang: String!) {
    prismic {
      article(uid: $uid, lang: $lang) {
        _meta {
          type
          id
          lang
          uid
          firstPublicationDate
          lastPublicationDate
        }
        title
        body {
          ... on PRISMIC_ArticleBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_ArticleBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_ArticleBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        seo_description
        seo_meta_title
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default Article;
